import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import { myStyles, BootstrapButton } from './styles';
import { themeStyles } from '../../../styles';
import ThemeContext from '../../../utils/theme-context';

export default function HeaderButton({
  headerButton
}) {
  const partnerTheme = useContext(ThemeContext);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');
  const classes = myStyles();
  const globalthemClasses = themeStyles(partnerTheme);
  const navigateToPage = (path) => [
    navigate(path)
  ];

  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained' || partnerTheme?.buttonStyle?.buttonType === 'outlined' || partnerTheme?.buttonStyle?.buttonType === 'text' ? partnerTheme?.buttonStyle?.buttonType : 'contained';
  let btnClassName;
  if (variantVal === 'contained') {
    btnClassName = globalthemClasses.containedBtn;
  } else if (variantVal === 'outlined') {
    btnClassName = globalthemClasses.outlinedBtn;
  } else {
    btnClassName = globalthemClasses.textBtn;
  }

  let showModal;
  if (ast) {
    showModal = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        showModal = '/customer-info';
        break;
      case 'Buying Only':
        showModal = '/customer-info';
        break;
      case 'Buying, Selling':
        showModal = '/service-selection';
        break;
      case 'Selling, Buying':
        showModal = '/service-selection';
        break;
      default:
        showModal = '/#embedded-video';
    }
  }

  return (
    <div id="header-section-signup-btn" className={classes.HeaderButtonRes}>
      <BootstrapButton
        component={Link}
        id="header-signup-btn"
        variant={variantVal}
        color="primary"
        disableRipple
        className={clsx(`${btnClassName}`, classes.signupButton)}
        onClick={() => {
          navigateToPage(showModal);
        }}
        size="large"
        to={showModal}
        role="link"
      >
        {headerButton?.label}
      </BootstrapButton>
    </div>
  );
}
